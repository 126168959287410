import React, { useState } from 'react';
import { TextField, Select, FormControl, MenuItem, InputLabel, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Row, Navbar, NavbarBrand, Nav } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import CssBaseline from '@material-ui/core/CssBaseline';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import validate from 'validate.js';
import listProvince from './Data/Province'
import Kota from './Data/Kota'
import GQL from './GQL'
import './App.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import logoImg from './Data/logo.png'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


let urlHelp, urllogin

if (process.env.REACT_APP_COMPANY === "IPOS") {
    urlHelp = "https://ipos.co.id/"
    urllogin = "https://portal.ipos5.co.id/"
} else if (process.env.REACT_APP_COMPANY === "HOMANPOS") {
    urlHelp = ""
    urllogin = ""
}

const useStyles = makeStyles((theme) =>
    createStyles({
        control: {
            padding: theme.spacing(2),
        },
    }),
);

const { listKota, listKotaCheck } = Kota;
const App = (props) => {
    const classes = useStyles();
    const [isDone, setIsDone] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [activeStep, setActiveStep] = useState(0);
    const [form, setForm] = useState({
        kodeoutlet: '',
        namaoutlet: '',
        database: '',
        userdb: '',
        pwddb: '',
        tax: 0,
        service: 0,
        fullName: '',
        email: '',
        phoneNumber: '',
        address: '',
        country: 'IDN',
        province: '',
        city: '',
        zipCode: '',
        isTrialMode: true,
        trialKategori: 2,
        trialSubKategori: 20,
        trialBarang: 50,
        trialTransaksiPerDay: 20,
        connectHoman: false,
        mysqlHost: '',
        mysqlDb: '',
        mysqlUser: '',
        mysqlPwd: '',
        salesreferencecode: '',
        jumlahdevice: 1,
        usePassti: false,
        useCashlez: false,
        useQREN: false,
        isOpname: false,
        password: '',
        nik: '',
        gender: ''
    })

    const getKota = () => {
        if (form.province && listKota[form.province]) return listKota[form.province];
        return [];
    }
    const handleChange = (ev) => {
        let newState = { ...form }
        let value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        if (['isTrialMode', 'connectHoman', 'usePassti', 'useCashlez', 'isTes', 'isOpname', 'useQREN', 'useBarangOutlet', 'useDelivery'].indexOf(key) > -1) {
            value = !!!form[key];
        } else if (['salesreferencecode'].indexOf(key) > -1) {
            value = value || null;
        } else if (ev.target.type === "number") {
            value = +value;
        }
        newState[key] = value;
        setForm(newState)
    }
    const validateForm = () => {
        const promNamaOutlet = new Promise((resolve, reject) => {
            const { namaoutlet, fullName, email, phoneNumber, address, gender, password, city, nik, country, zipCode, tax, service, province } = form;
            const constraint = {
                namaoutlet: {
                    length: {
                        minimum: 3,
                        message: "Nama outlet minimal terdiri dari 3 huruf"
                    },
                }, fullName: {
                    length: {
                        minimum: 3,
                        message: "Nama minimal terdiri dari 3 huruf"
                    }
                }, email: {
                    email: {
                        message: "Email tidak valid"
                    }
                }, address: {
                    length: {
                        miminum: 10,
                        message: "Alamat terlalu pendek. Harap mengisi alamat lengkap"
                    }
                }, gender: {
                    length: {
                        miminum: 1,
                        message: "Gender wajin isi"
                    }
                }, password: {
                    length: {
                        miminum: 10,
                        message: "Password terlalu pendek. Harap mengisi minimal 8 digit"
                    }
                }, nik: {
                    length: {
                        miminum: 1,
                        message: "NIK wajib isi"
                    }
                },
                phoneNumber: {
                    length: {
                        minimum: 9,
                        message: "Nomor Handphone terlalu pendek"
                    },
                },
                country: {
                    inclusion: {
                        within: ["IDN"],
                        message: "Harap memilih negara yang valid"
                    }
                },
                province: {
                    inclusion: {
                        within: listProvince,
                        message: "Harap memilih propinsi yang valid"
                    }
                },
                city: {
                    inclusion: {
                        within: listKotaCheck,
                        message: "Harap memilih kota yang valid"
                    }
                },
                zipCode: {
                    length: {
                        is: 5,
                        message: "Harap mengisi kode pos dengan format yang sesuai"
                    },
                    format: {
                        pattern: "[0-9]+",
                        message: "Mohon mengisi kode pos dengan nomor saja"
                    }
                },
                tax: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: "Harap mengisi persentase pajak yang sesuai"
                    }
                },
                service: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: "Harap mengisi persentase service yang sesuai"
                    }
                }
            }
            const validator = validate({ namaoutlet, fullName, email, phoneNumber, address, gender, password, city, nik, country, zipCode, tax, service, province }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promNamaOutlet]);
    }

    const handleSimpan = () => {
        console.log(form)
        validateForm().then(() => {
            const { namaoutlet, fullName, email, phoneNumber, address, city, country, zipCode, tax, service, isTrialMode, connectHoman, province, salesreferencecode, isOpname, useQREN, gender, password, nik } = form;
            const _address = address.replace(/\n/g, "<BR>");
            const query = `
                    mutation{
                        createOutletByRegister(
                            input:{
                            namaoutlet:"${namaoutlet}",
                            fullName:"${fullName}",
                            email:"${email}",
                            phoneNumber:"${phoneNumber}",
                            address:"${_address}",
                            city:"${city}",
                            country:"${country}",
                            zipCode:"${zipCode}",
                            tax:${tax},
                            service:${service},
                            isTrialMode:${isTrialMode},
                            connectHoman:${connectHoman},
                            province:"${province}",
                            salesreferencecode:"${salesreferencecode}",
                            isOpname: ${isOpname},
                            useQREN:${useQREN},
                            password:"${password}",
                            nik:"${nik}",
                            gender:${gender}
                            }
                      )
                    }
                  `
            GQL(query).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data.createOutletByRegister) {
                    setIsDone(true)
                }
            }).catch(err => {
                setAlertText(err)
                setIsAlert(true)
            })
        }).catch(err => {
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key][0] + "</br>";
            }

            setAlertText(errorMessage)
            setIsAlert(true)
        })
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <>
            <Navbar className="bg-theme" light expand="md">
                <NavbarBrand className="color-white">
                    <img alt="logo" width="100" src={logoImg} />
                </NavbarBrand>
                <Nav className="mr-auto" navbar></Nav>
                <Nav className="hideMobile">
                    {/* eslint-disable-next-line */}
                    <a target="_blank" class="nav-link" href={urlHelp}>HELP</a>
                    {/* eslint-disable-next-line */}
                    <a target="_blank" class="nav-link" href={urllogin}>SIGN IN</a>
                </Nav>
            </Navbar>
            <CssBaseline />
            <div className="form">
                {!isDone ? (
                    <React.Fragment>
                        <Row>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="flex-start"
                            >

                                <Grid item xs={12} md={4}>
                                    <Card className={classes.root}>
                                        <CardContent>
                                            <h4 className="text-center">Start with IPOS now</h4>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                <Stepper activeStep={activeStep} alternativeLabel>
                                                    <Step>
                                                        <StepLabel>Informasi Outlet</StepLabel>
                                                    </Step>
                                                    <Step>
                                                        <StepLabel>Informasi Pengguna</StepLabel>
                                                    </Step>
                                                    <Step>
                                                        <StepLabel>Lokasi Pengguna</StepLabel>
                                                    </Step>
                                                </Stepper>
                                            </Typography>
                                            {/* Informasi Outlet */}
                                            {activeStep === 0 && (
                                                <>
                                                    <TextField
                                                        id="namaoutlet"
                                                        label="Nama Outlet"
                                                        value={form.namaoutlet}
                                                        fullWidth
                                                        required
                                                        onChange={handleChange}
                                                        type="text"
                                                    />
                                                    <TextField
                                                        id="email"
                                                        label="Email"
                                                        value={form.email}
                                                        fullWidth
                                                        required
                                                        type="email"
                                                        onChange={handleChange}

                                                    />
                                                    <TextField
                                                        id="password"
                                                        label="Password"
                                                        value={form.password}
                                                        fullWidth
                                                        required
                                                        type="password"
                                                        onChange={handleChange}
                                                    />
                                                    <TextField
                                                        id="address"
                                                        label="Alamat"
                                                        value={form.address}
                                                        fullWidth
                                                        required
                                                        multiline={true}
                                                        rows={3}
                                                        onChange={handleChange}
                                                    />
                                                    <TextField
                                                        id="phoneNumber"
                                                        label="Telp"
                                                        value={form.phoneNumber}
                                                        fullWidth
                                                        required
                                                        type="telp"
                                                        onChange={handleChange}
                                                    />
                                                    {/* <TextField
                                                        id="tax"
                                                        label="Tax"
                                                        value={form.tax}
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        onChange={handleChange}
                                                    />
                                                    <TextField
                                                        id="service"
                                                        label="Service"
                                                        value={form.service}
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        onChange={handleChange}
                                                    /> */}
                                                </>
                                            )}
                                            {/* Informasi Pengguna */}
                                            {activeStep === 1 && (
                                                <>
                                                    <TextField
                                                        id="fullName"
                                                        label="Nama Lengkap"
                                                        value={form.fullName}
                                                        fullWidth
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <TextField
                                                        id="nik"
                                                        label="NIK (ID KTP)"
                                                        value={form.nik}
                                                        fullWidth
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <div style={{ marginTop: 15 }}>
                                                        <FormLabel component="legend">Gender</FormLabel>
                                                        <RadioGroup
                                                            aria-label="gender"
                                                            name="gender"
                                                            onChange={handleChange}
                                                            value={form.gender}
                                                        >
                                                            <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                                                            <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                                                        </RadioGroup>
                                                    </div>
                                                </>
                                            )}
                                            {/* Lokasi Pengguna */}
                                            {activeStep === 2 && (
                                                <>
                                                    <FormControl required fullWidth={true}>
                                                        <InputLabel>Negara</InputLabel>
                                                        <Select
                                                            name="country"
                                                            value={form.country}
                                                            required
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={'IDN'}>Indonesia</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl required fullWidth={true}>
                                                        <InputLabel>Propinsi</InputLabel>
                                                        <Select
                                                            name="province"
                                                            value={form.province}
                                                            required
                                                            onChange={handleChange}
                                                        >
                                                            {listProvince.map((el, idx) => (
                                                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl required fullWidth={true}>
                                                        <InputLabel>Kota</InputLabel>
                                                        <Select
                                                            name="city"
                                                            value={form.city}
                                                            required
                                                            onChange={handleChange}
                                                        >
                                                            {getKota().map((el, idx) => (
                                                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        id="zipCode"
                                                        label="Kode Pos"
                                                        value={form.zipCode}
                                                        fullWidth
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </>
                                            )}
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" fullWidth color="secondary" variant="outlined" disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                                            {activeStep === 2 ? (
                                                <Button size="small" fullWidth color="primary" variant="contained" onClick={handleSimpan}>Submit</Button>
                                            ) : (
                                                <Button size="small" fullWidth color="primary" variant="contained" onClick={handleNext}>Next</Button>
                                            )}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Row><h3>Selamat outlet anda berhasil register</h3></Row>
                        <Row><p></p></Row>
                        <Row>
                            <p>Untuk login ke aplikasi <b>iPOS</b>, harap masukan <b>token</b> yang sudah kami kirim di <b>email</b> anda <b>{form.email}</b>.<br />
                                Untuk login ke <b>head office</b> dengan link <a href="https://portal.ipos5.co.id/">portal.ipos5.co.id</a>. <br />
                                Untuk login ke <b>laporan</b> dengan link <a href="https://laporan.ipos5.co.id/">laporan.ipos5.co.id</a>.<br />
                                Login dengan <b>email</b> dan <b>password</b> yang telah anda buat sebelumnya.</p>
                            <p>Bila Anda memiliki pertanyaan atau kendala yang dihadapi saat menggunakan <b>iPOS</b>, Anda bisa menghubungi Layanan Customer Service kami via e-mail <b> cs@ipos.co.id</b> ataupun WhatsApp kami: <b>0811-7615-758 / 0811-7757-582</b></p>

                        </Row>
                    </React.Fragment>
                )}
                <Modal show={isAlert} onHide={() => setIsAlert(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Perhatian</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p dangerouslySetInnerHTML={{ __html: `<span>${alertText}</span>` }}></p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setIsAlert(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default App;
